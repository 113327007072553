.MenuLists {
    display: flex;
    align-items: center;
}
.Menu {
    font-size: 18pz;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 0;
    width: 50%;
    text-align: center;
    color: #747474;
    cursor: pointer;
    border-bottom: 1px solid #E4E4E4;
}
.Active {
    color: #000;
    border-bottom: 1px solid #000;
}
