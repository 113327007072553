.CFWrap {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}
.CFTitle {
    font-size: 20px;
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 25px;
    color: #191919;
}
.ResultList {
    border: 1px solid #777;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 15px;
}
.ResultList p {
    margin-bottom: 10px;
}