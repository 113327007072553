.Footer {
    width: 100%;
    max-width: 430px;
    margin: 60px auto 0 auto;
    background-color: #EDEDED;
}

.Footer .FooterWrap {
    width: 360px;
    margin: auto;
    padding: 25px 0;
}
.Footer .FooterMenu {
    margin-left: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 2em;
    color: #191919;
    margin-bottom: 10px;
}

.Footer .FTMenu:hover {
    color: #094891;
}
.Footer .FooterContent {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 2em;
    color: #747474;
}