.QnAOUT{
    width: 100%;
    height: 100%;
    background-color: #333333;
    position: absolute;
    top:0;
    left:0;
    z-index: 999;
    animation: modal-bg-show 0.3s;
}
@keyframes modal-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
.QnA {
    width: 330px;
    height: calc(100% - 150px);
    z-index: 999;
    margin: 30px auto;
    background-color: #FFF;
    border: 1px solid black;
    border-radius: 8px;
    animation: modal-show 0.3s;
}
@keyframes modal-show {
    from {
      opacity: 0;
      margin-top: -50px;
    }
    to {
      opacity: 1;
      margin-top: 30px;
    }
}

.QnAContent {
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    height: calc(100% - 60px);
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-end;
}
.QnAContent::-webkit-scrollbar {
    display: none;
  }

.CloseModal {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #747474;
    margin-bottom: 20px;;
}
.CloseModal::after {
    content: "";
    position: absolute;
    left: -2px;
    bottom: -8px;
    height: 2px;
    width: 31px;
    background: #747474;
}
.ModalSection {
    margin-bottom: 25px;
}
.ModalTitle {
    font-size: 18px;
    font-weight: bold;
    color: #094891;
    margin-bottom: 25px;
}
.ModalSubTitle {
    font-size: 14px;
    font-weight: 600;
    color: #094891;
    margin-bottom: 10px;
}
.ModalSubTitle_2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.ModalContent {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1.25em;
}