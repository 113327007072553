/** Common */
@font-face {
  font-family: 'AppleSDGothicNeoM';
  src: url('./assets/font/AppleSDGothicNeoM.ttf');
}
.App {
  position: relative;
  width: 100%;
  height: 100%;
}
.AppContainer {
  min-height: calc(100vh - 150px);
}
body {
  font-family: 'AppleSDGothicNeoM';
  width: 100%;
  height: 100%;
}
.Container {
  width: 100%;
  max-width: 360px;
  margin: auto;
}
.react-datepicker-popper {
  position: relative;
  transform: none;
}
.DF_Col {
  display: flex;
  flex-direction: column;
}
.DF_Row {
  display: flex;
  flex-direction: row;
}
.Counsel {
  margin-top: 40px;
}
.Confirm {
  margin-top: 40px;
}
.DN {
  display: none;
}
.QnAOpen{
  font-size: 18px;
  text-align: center;
}
.PO-R {
  position: relative;
}
.QnAOpenBtn {
  position: absolute;
  bottom: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #BC2F34;
}
.QnAOpenBtn:hover {
  color: #DB0A12;
}
/* .QnAOpenBtn::after {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -5px;
  height: 1px;
  width: 75px;
  background: #BC2F34;
} */
.Title {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 30px;
  color: #191919;
}
.Title_2 {
  font-size: 26px;
  font-weight: 800;
  margin-bottom: 15px;
  color: #191919;
}
.Title_3 {
  font-size: 18px;
  line-height: 1.75em;
  font-weight: 800;
  margin-bottom: 20px;
  color: #000;
}
.SubTitle {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #191919;
}
.SubTitle_2 {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
}
.SubTitle_3 {
  font-size: 18px;
  font-weight: 400;
  width: 90px;
  color: #191919;
}
.SubContent {
  font-size: 18px;
  font-weight: bold;
  color: #747474;
}
.SubContent_2 {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}
.SubContent_3 {
  font-size: 16px;
  font-weight: 400;
  color: #747474;
  line-height: 1.5em;
}
.Input {
  padding: 10px 15px;
  color: #000;
  border: 1px solid #DBDBDB;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  margin-bottom: 15px;
}
.W100{
  width: 90%;
}
.BoxRQ {
  padding: 15px 18px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #EDEDED;
  margin-bottom: 15px;
  cursor: pointer;
  border: none;
}
.BoxRQ_2 {
  display: flex;
  align-items: center;
  padding: 7px 18px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #EDEDED;
  margin-bottom: 15px;
  cursor: pointer;
  border: none;
}
.BoxRQ span {
  margin-left: 15px;
}
.BoxSmallRQs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.BoxSmallRQ {
  width: 40%;
  display: flex;
  padding: 15px;
  display: flex;
  border: 1px solid #DBDBDB;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}
.Select {
  appearance:none;
  background:url('./assets/img/DownArrow.png') no-repeat right 24px center;
  background-color: #EDEDED;
}
.TextArea {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  height: 156px;
  padding: 15px;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  margin-bottom: 40px;
}
.ClockIcon {
  margin-right: 10px;
}
.KakaoLabel {
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.KakaoPayIcon {
  width: 50px;
  margin-right: 5px;
}
.TimeActive {
  border: 1px solid #000;
  background: #FFF;
}
.PaymentActive {
  background: #000;
  color: #FFF;
}
.KakaoActive {
  background: #ffe200;
}
.Gray {
  color: #747474;
}
.Btn {
  border-radius: 30px;
  border: none;
  /* background: #094891; */
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
  margin-bottom: 30px;
  background:url('./assets/img/RightArrow.png') no-repeat right 28% center;
  background-color: #094891;
}
.Btn_2 {
  width: 185px;
  border-radius: 30px;
  border: none;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 0;
  margin: 0 auto 30px auto;
  background-color: #094891;
}
.BtnSmGray {
  width: 185px;
  padding: 15px 0;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background-color: #E5E5E5;
  color: #191919;
  margin: 35px auto;
}
.BtnSmBlue {
  width: 185px;
  padding: 15px 0;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background-color: #094891;
  color: #191919;
}
.BtnSmRQ {
  padding: 10px 16px;
  background: #094891;
  color: #FFF;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.BtnRQ {
  padding: 15px 47px;
  border: none;
  border-radius: 26px;
  font-size: 16px;
  font-weight: bold;
}
.CancelBtn {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #747474;
}
.CancelBtn::after {
  content: "";
  position: absolute;
  left: -2px;
  bottom: -10px;
  height: 2px;
  width: 64px;
  background: #747474;
}
.BtnSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.bg-gray {
  background-color: #E5E5E5;
  color: #191919;
}
.bg-blue {
  background-color: #094891;
  color: #FFF;
}
.Notice {
  font-size: 14px;
  color: #747474;
  line-height: 1.5em;
  margin-bottom: 30px;
}
.Notice_2 {
  font-size: 14px;
  color: #747474;
  line-height: 1.5em;
  margin-bottom: 15px;
}
.Notice_3 {
  font-size: 12px;
  color: #747474;
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.bg-ivory{
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #EDEDED;
  margin-left: -30px;

}
.bd-br {
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-22 {
  margin-left: 22px;
}
.ml-40{
  margin-left: 40px;
}
.wd-60 {
  width: 62%;
}
.CP {
  cursor: pointer;
}
.SelectPD {
  padding: 0.3em 1.4em 0.3em 0.8em;
}

.mr-20 {
  margin-right: 20px;
}
.wd-75 {
  width: 75px;
}
.Status {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  padding: 25px 0;
  background: #EFEFEF;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: #747474;
}
.StatusText {
  margin-right: 10px;
}
.StatusActive {
  color: #094992;
}
.mt-2 {
  margin-top: 2px;
}
.NoticeSection {
  padding: 20px 0 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.NoticeSection_2 {
  padding: 25px 0 15px 0;
  margin: 0 auto 30px auto;
  max-width: 430px;
  width: 100%;
  background-color: #EFEFEF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.Alert {
  font-size: 16px;
  font-weight: bold;
  color : #0076FF;
}
.TimeActive {
  background:url('./assets/img/Check.png') no-repeat right 24px center;
}
.CheckBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
.CheckBoxInfo {
  color: #747474;
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .Container {
    width: 90%;
    margin: auto;
  }
}